import { Alert, Autocomplete, Box, Button, Container, Divider, FormControl, FormHelperText, FormLabel, Grid, IconButton, Menu, MenuItem, Pagination, PaginationItem, Paper, Select, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import {
    useGridApiContext, useGridSelector, gridPageCountSelector,
    gridPageSelector,
    GridRowModes
} from "@mui/x-data-grid-pro";
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { handleExistingInvoice, mieSearch, setAutocomplete, setDateValue, setExistingInvoice, setSupplierCode, spcProductSeter, spcRowParams, spcValueSeter } from "../../store/slices/manualInvoice";
import { useDispatch, useSelector } from 'react-redux';
import axios_supplier from "../../services/utility/axios-supplier";
import axiosProductDistribution from '../../services/utility/axios-product-distribution';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ExistingInvoiceModal from "./manualEntryInvoices/ExistingInvoiceModal";
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";
import axios_utilities from "../../services/utility/axios-utilities";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import { negativeNumber, negativeremove } from "../../services/utility/common-utils";
import axios_invoice_summaries from '../../services/utility/axios-invoice-summaries';
import PageLoader from "../../components/shared/PageLoader";
import AddManualEntryModal from "./manualEntryInvoices/AddManualEntryModal";
import DeleteIcon from '@mui/icons-material/Delete';
import { filterFlagChange, resetExternalFilter } from "../../store/slices/externalFilter";
import { resetDataGrid } from "../../store/slices/dataGrid";
// const Item = styled(Paper)(({ theme }) => ({
//     paddingLeft: theme.spacing(1),
//     paddingRight: theme.spacing(1),
//     boxShadow: "none"
// }));

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
    boxShadow: "none",
    background: "none"
}));

function ManualEntryInvoice() {
    const {
        manualInvoiceEntry
    } = useSelector((state) => {
        return {
            manualInvoiceEntry: state.invoiceManualEntry
        }
    });

    const dispatch = useDispatch();

    const apiRef = useGridApiRef()

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const rowDataList = [10, 25, 50, 100, 200, 300]

        const handleChange = (event) => {
            //dispatch(setRowPage({ pageData: event.target.value }));
            setState({ ...state, pageSize: event.target.value })
        };

        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            //dispatch(setPageNo({ page: value }));
            setState({ ...state, page: value })
        }

        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        {/* <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={state.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }

                                    )}
                                </Select>

                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div> */} &nbsp;
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }
    const [vat_code, setVat_code] = useState({
        vat_code_selected: null,
        vat_code_options: [],
        validateDisable: false
    })
    const [vat_amount, setVat_amount] = useState({
        vat_amount_manual: true,
        vat_amount_old: null,
        vat_amount_new: null
    })
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (vat_code.vat_code_options.length === 0) {
                axios_utilities.get('vatcodes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setVat_code({ ...vat_code, vat_code_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
        }
        fetchMyAPI();
        resetAll()
    }, [])
    // const IconMenuLink = ({ params, onDelete }) => {
    //     const { row } = params;
    //     const [anchorEl, setAnchorEl] = useState(null);
    //     const open = Boolean(anchorEl);

    //     const handleClick = (event) => {
    //         setAnchorEl(event.currentTarget);
    //     };
    //     const handleClose = (event, action) => {
    //         event.preventDefault();
    //         if (action === 'edit') {
    //             //handle edit function
    //         } else if (action === 'delete') {
    //             if (onDelete) {
    //                 onDelete(params)
    //             }
    //         }
    //         setAnchorEl(null);
    //     };

    //     return (<>
    //         <IconButton
    //             aria-label="more"
    //             aria-controls={open ? 'long-menu' : undefined}
    //             aria-expanded={open ? 'true' : undefined}
    //             aria-haspopup="true"
    //             onClick={handleClick}
    //             id={row.id + 1}
    //         >
    //             <MoreVertIcon />
    //         </IconButton>
    //         <Menu
    //             id={row.id}
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleClose}
    //             MenuListProps={{
    //                 'aria-labelledby': 'basic-button',
    //             }}
    //             className="actionMenuBx"
    //         >
    //             {/* <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem> */}
    //             <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
    //         </Menu>

    //     </>)
    // }
    function SelectEditInputCell(props) {
        const {
            manualInvoiceEntry
        } = useSelector((state) => {
            return {
                manualInvoiceEntry: state.invoiceManualEntry
            }
        });
        const dispatch = useDispatch();

        const { id, value, field } = props || manualInvoiceEntry.spcRowParams;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };
        const addSPC = (e) => {
            e.preventDefault()
            dispatch(spcRowParams({ id, value, field }))
            if (manualInvoiceEntry.mie_supplier.supplier_code_selected === null || mie_supplier === "" || mie_supplier === null) {
                setSnackBar({ ...snackBar, show: true, text: "Please select supplier", type: "error" })
            } else {
                setState({ ...state, handleSPC: true })
            }
        }

        useEffect(() => {
            if (manualInvoiceEntry.setSpcValue !== null) {
                addSPCField()
            }
        }, [state.handleSPC === false])

        const addSPCField = async () => {
            const { id, field, value } = manualInvoiceEntry.spcRowParams;
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            const spcValue = manualInvoiceEntry.setSpcValue;
            const newBx = rows;
            const rowSpcvalue = spcValue;
            const ids = id;
            await apiRef.current.setEditCellValue({ id, field, value: spcValue });
            apiRef.current.stopCellEditMode({ id, field });
            newBx.forEach(x => {
                if (x.id === ids) {
                    x.spc = rowSpcvalue
                    x.product = spcValue.productName
                    x.vatCode = spcValue.vatCode
                }
            });
            dispatch(spcValueSeter(null))
        }
        const clearSPC = async (event) => {
            dispatch(spcRowParams({ id: '', value: '', field: '' }))
            await apiRef.current.setEditCellValue({ id, field, value: "" });
            apiRef.current.stopCellEditMode({ id, field });
            dispatch(spcValueSeter(null))
            dispatch(spcProductSeter(""))
        }
        const hideSPCModal = (params) => {
            setState({ ...state, handleSPC: params === false ? params : true })
        }
        return (
            <Box component={'div'} sx={{ padding: 1 }}>
                <Grid container alignItems="center">
                    <Grid item xs={9} md={8.5}>
                        <FormControl size={'small'} fullWidth>
                            <TextField
                                inputProps={{ maxLength: 100 }}
                                size="small"
                                id="pdc_gen_product"
                                aria-describedby="pdc_gen_product_error"
                                value={value ? value.supplierProductCode : value}
                                name="pdc_gen_product"
                                onChange={handleChange}
                                disabled={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid container justifyContent={"center"} item xs={3} md={3.5} sx={{ paddingLeft: 1 }}>
                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, }} onClick={clearSPC} startIcon={<ClearIcon />}></Button>
                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => { addSPC(e) }} startIcon={<EditIcon />}></Button>
                    </Grid>
                </Grid>
                {
                    state.handleSPC && <CommonPopUp
                        onShow={state.handleSPC}
                        size="large_overlay"
                        supplierMemberCode={{
                            title: 'SPC',
                            supplier: mie_supplier || null
                        }}
                        onHide={hideSPCModal}
                    />
                }
            </Box>
        );
    }
    const renderSPCeditCell = (params) => {
        return <SelectEditInputCell {...params} />;
    };

    function SelectVatCode(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event, newValue) => {
            // console.log(event, newValue)
            // if (event.target.value !== "" || event.target.value !== null) 
            if (newValue !== "" || newValue !== null) 
            {
                setVat_code({ ...vat_code, vat_code_selected: newValue, validateDisable: false });
                setVat_amount({ ...vat_amount, vat_amount_manual: false });
            }
            if(newValue === null || !newValue)
            {
                setVat_code({ ...vat_code, validateDisable: true });
            }
            await apiRef.current.setEditCellValue({ id, field, value: newValue ? newValue : { code: "" } });
            apiRef.current.stopCellEditMode({ id, field });
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        };

        return (
            <Autocomplete
                fullWidth
                defaultValue={value ? value : null}
                id="general_vat_code_search"
                options={vat_code.vat_code_options}
                getOptionLabel={(option) => option.code || ""}
                renderOption={(props, option) => (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        position: 'relative'
                    }} {...props}>
                        <Box sx={{ paddingRight: '10px' }}>{option.code}</Box>
                        <Box sx={{ position: 'absolute', right: '10px', top: '8px', fontSize: '11px' }}>({option.rate})</Box>
                    </Box>)}
                componentsProps={{
                    paper: {
                        sx: {
                            fontSize: '0.85rem'
                        }
                    }
                }}
                onChange={(event, newValue) => {
                    handleChange(event, newValue)
                }}
                renderInput={(params) =>
                    <FormControl size={'small'} fullWidth>
                        <div className="input-group">
                            <TextField {...params}
                                size="small"
                                id="general_vat_code"
                                aria-describedby="general_vat_code-error"
                            />
                        </div>
                    </FormControl>
                }

            />
        );
    }
    const renderVatCodeEditCell = (params) => {
        return <SelectVatCode {...params} />
    }

    const columns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true,
        },
        {
            headerName: "SPC",
            field: "spc",
            flex: 1,
            minWidth: 200,
            renderEditCell: renderSPCeditCell,
            editable: true,
            valueFormatter: ({ value }) => value.supplierProductCode
        },
        {
            headerName: "Product",
            field: "product",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                return params.row.spc.productName
            }
        },
        {
            headerName: "Quantity",
            field: "mieQuantity",
            flex: 1,
            minWidth: 150,
            editable: true,
            type: 'number',
            align: 'left',
            headerAlign: 'left',
        },
        {
            headerName: "Unit Price",
            field: "unitPrice",
            flex: 1,
            minWidth: 150,
            editable: true,
            type: 'number',
            align: 'left',
            headerAlign: 'left',
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            headerName: "VAT Code",
            field: "vatCode",
            flex: 1,
            minWidth: 200,
            renderEditCell: renderVatCodeEditCell,
            editable: true,
            valueFormatter: ({ value }) => value.code
        },
        {
            headerName: "VAT Amount",
            field: "vatAmmount",
            flex: 1,
            minWidth: 150,
            editable: true,
            type: 'number',
            valueFormatter: ({ value }) => Number(value).toFixed(2)
        },
        {
            headerName: "Line Value",
            field: "lineValue",
            flex: 1,
            minWidth: 150
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            align: 'center',
            renderCell: (params) => {
                const { id } = params;
                return (<>
                    {
                        rowModesModel[id] && rowModesModel[id].mode === 'edit' ? (<Button variant="text" size='small' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => { removeEditRow(e, params); }} startIcon={<CloseIcon />}></Button>) : (<Button variant="text" size='small' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => { editRow(e, params); }} startIcon={<EditIcon />}></Button>)

                    }
                    <IconButton
                        aria-label="more"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => onDeleteRow(e, params)}
                        color="primary"
                        sx={{ padding: '0' }}
                    >
                        <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                    </IconButton>
                </>);
            },
        }
    ];

    const [state, setState] = useState({
        brand_name: "",
        hasBrandName: false,
        loader: false,
        formValid: false,
        pageLoader: false,
        rowData: null,
        defaultGrid: {
            paginationMode: 'client',
        },
        existingInvoiceModal: false,
        isLoading: false,
        total: 0,
        page: 1,
        pageSize: 10,
        supplierMemberDasboadModal: false,
        supplierCodeDasboadModal: false,
        handleSupplierMemberModal: '',
        supplierdashboardname: '',
        supplierdashboardcode: '',
        handleSPC: false,
        supplierInvoiceCode: '',
        netTotal: '',
        vatTotal: '',
        grossTotal: '',
        existingData: null,
        addMinus: false,
        submitDisable: true,
        validateInvoiceData: null,
        handleManualEntryModal: false,
        pageError: false,
        validationError: [],
        finalSubmitError: "",
        submitError: []
    });
    const [convertMinus, setConvertMinus] = useState(false)
    const [rows, setRows] = useState([])
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const onPageChange = (newPage) => {
        setState({ ...state, pageSize: newPage + 1 });
    };
    const onPageSizeChange = (newPageSize) => {
        setState({ ...state, pageSize: newPageSize });
    };

    const handleSelection = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(mieSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (manualInvoiceEntry.mie_distribution_channel.distribution_channel_options.length === 0) {
                axiosProductDistribution.get('distributionChannels/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'mie_distribution_channel',
                                key: 'distribution_channel_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
            if (manualInvoiceEntry.mie_supplier.supplier_code_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(mieSearch({
                                parent: 'mie_supplier',
                                key: 'supplier_code_options',
                                value: unique
                            }))
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const handleInvoiceDate = (event) => {
        dispatch(setDateValue({
            key: 'invoiceDate',
            value: event === null ? null : moment(event, 'DD/MM/YYYY')
        }))
        dispatch(setDateValue({
            key: 'deliveryDate',
            value: event === null ? null : moment(event, 'DD/MM/YYYY')
        }))
    }
    const handleDeliveryDate = (event) => {
        dispatch(setDateValue({
            key: 'deliveryDate',
            value: event === null ? null : moment(event, 'DD/MM/YYYY')
        }))
    }
    const handleExistingInvoiceModal = (params) => {
        dispatch(resetExternalFilter())
        dispatch(resetDataGrid());
        dispatch(filterFlagChange(false))
        dispatch(handleExistingInvoice(true))
    }

    const CommonPopUpModal = (event, type) => {
        if (type === 'supplier') {
            setState({ ...state, supplierMemberDasboadModal: true, handleSupplierMemberModal: type })
        }
        if (type === 'smacode') {
            if (manualInvoiceEntry.mie_supplier.supplier_code_selected === null || manualInvoiceEntry.mie_supplier.supplier_code_selected === "") {
                setSnackBar({ ...snackBar, show: true, text: "Please select supplier", type: "error" })
            } else {
                setState({ ...state, supplierCodeDasboadModal: true, handleSupplierMemberModal: type })
            }
        }
    }

    const hideSupplierMemberModal = (event, reason, type) => {
        if (type === 'supplier') {
            setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
        }
        if (type === 'smacode') {
            setState({ ...state, supplierCodeDasboadModal: event === false ? event : true })
        }
    }

    const getSupplierDetail = (e, type) => {
        if (e !== '') {
            if (type === 'supplier') {
                setState({ ...state, supplierMemberDasboadModal: false })
                setMie_supplier(e)
                dispatch(mieSearch({
                    parent: 'mie_supplier',
                    key: 'supplier_code_selected',
                    value: e
                }))
            }
            if (type === 'smacode') {
                setState({ ...state, supplierdashboardcode: e, supplierCodeDasboadModal: false })
            }
        }
    }

    const clearSMC = (event, type) => {
        if (type === 'supplier') {
            setMie_supplier(null)
            dispatch(mieSearch({
                parent: 'mie_supplier',
                key: 'supplier_code_selected',
                value: null
            }))
        }
        if (type === 'smacode') {
            setState({ ...state, supplierdashboardcode: '' })
        }
    }

    const onExitingSelection = (row) => {
        resetAll()
        setTimeout(() => {
            dispatch(setExistingInvoice({
                key: 'existingData',
                value: row
            }))
        }, 150);

    }
    useEffect(() => {
        if (manualInvoiceEntry.existingData !== null) {


            // eslint-disable-next-line max-len
            setRows([])
            setState({
                ...state,
                supplierInvoiceCode: manualInvoiceEntry.existingData.supplierInvoiceCode,
                netTotal: manualInvoiceEntry.existingData.netTotal,
                vatTotal: manualInvoiceEntry.existingData.vatTotal,
                grossTotal: manualInvoiceEntry.existingData.grossTotal,
                supplierdashboardcode: { smaCode: manualInvoiceEntry.existingData.supplierMemberAccountCode }
            })
            dispatch(mieSearch({
                parent: 'mie_supplier',
                key: 'supplier_code_selected',
                value: manualInvoiceEntry.existingData.supplier
            }))
           setMie_supplier({id: manualInvoiceEntry.existingData.supplier.id, name: manualInvoiceEntry.existingData.supplier.name})
           dispatch(mieSearch({
                parent: 'mie_distribution_channel',
                key: 'distribution_channel_selected',
                value: { id: manualInvoiceEntry.existingData.distributionChannel.id, name: manualInvoiceEntry.existingData.distributionChannel.name }
            }))
            setMie_distributionChannel({ id: manualInvoiceEntry.existingData.distributionChannel.id, name: manualInvoiceEntry.existingData.distributionChannel.name })
            dispatch(setSupplierCode({
                key: 'mieSupplierCodeOnDashboard',
                value: {
                    ...manualInvoiceEntry.mieSupplierCodeOnDashboard,
                    smaCode: manualInvoiceEntry.existingData.supplierMemberAccountCode,
                    memberOutLetId: manualInvoiceEntry.existingData.memberOutlet.id,
                    memberOutLetName: manualInvoiceEntry.existingData.memberOutlet.name,
                    onTrade: manualInvoiceEntry.existingData.memberOutlet.onTrade,
                    supplierId: manualInvoiceEntry.existingData.supplier.id,
                    supplierName: manualInvoiceEntry.existingData.supplier.name
                }
            }))

            dispatch(setDateValue({
                key: 'invoiceDate',
                value: moment(manualInvoiceEntry.existingData.invoiceDate, 'DD/MM/YYYY')
            }))
            dispatch(setDateValue({
                key: 'deliveryDate',
                value: moment(manualInvoiceEntry.existingData.deliveryDate, 'DD/MM/YYYY')
            }))
            let lines = [];
            manualInvoiceEntry.existingData.lines.forEach(x => {
                const id = Math.random().toString(16).slice(2);
                lines.push({
                    id,
                    description: x.description,
                    spc: { supplierProductCode: x.supplierProductCode, productName: x.product.name, productId: x.product.id },
                    product: x.product.name,
                    mieQuantity: x.supplierQuantity ? x.supplierQuantity : 0,
                    unitPrice: x.supplierUnitPrice,
                    vatCode: x.vatCode ? x.vatCode.id === 0 ? {
                        "id": 1,
                        "code": "S",
                        "rate": 0.2,
                        "description": "Standard",
                        "externalCode": "1"
                    } : x.vatCode : {
                        "id": 1,
                        "code": "S",
                        "rate": 0.2,
                        "description": "Standard",
                        "externalCode": "1"
                    },
                    vatAmmount: x.vatAmount,
                    lineValue: x.supplierLineValue,
                })
            });

            setTimeout(() => {
                setRows(lines)
            }, 1000);
        }
    }, [manualInvoiceEntry.existingData])
    const [rowModesModel, setRowModesModel] = useState({});
    const addNewEntry = () => {
        const id = Math.random().toString(16).slice(2);
        setRows((oldRows) => [{
            id,
            spc: { supplierProductCode: "", productName: "", productId: "", vatCode: null },
            product: "",
            mieQuantity: '',
            unitPrice: Number(0).toFixed(2),
            vatCode: {
                "id": 1,
                "code": "S",
                "rate": 0.2,
                "description": "Standard",
                "externalCode": "1"
            },
            vatAmmount: '',
            lineValue: '',
        }, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'spc' },
        }));
    }
    useEffect(() => {
        apiRef.current.updateRows(rows)
        setRows(rows)
        setState({ ...state, total: rows.length })
        if (state.submitDisable === false) {
            setState({ ...state, submitDisable: true })
        }
    }, [rows])

    const processRowUpdate = async (newRow) => {
        /* eslint-disable prefer-destructuring */
        // console.log("triggered", newRow.vatAmmount, vat_amount.vat_amount_manual, newRow.vatCode, newRow.vatCode.rate);
        let vatCode = null;
        const productName = newRow.spc ? newRow.spc.productName : '';
        if (newRow.product !== newRow.spc.productName) {
            vatCode = newRow.spc.vatCode;
        } else if (!newRow.spc) {
            vatCode = newRow.vatCode
        } else {
            vatCode = newRow.vatCode
        }
        if(newRow.vatCode.rate === undefined)
        {
            setSnackBar({ ...snackBar, show: true, text: "VAT Code cannot be blank!", type: "error" });
        }
        let mieQuantity = newRow.mieQuantity ? newRow.mieQuantity : 0;
        let unitPrice = newRow.unitPrice ? Number(newRow.unitPrice).toFixed(2) : Number(0).toFixed(2);
        let manaul_vatamount = (newRow.vatCode.rate === undefined) ? 0 : newRow.vatAmmount;
        let temp_vatamount = (unitPrice && newRow.vatCode && mieQuantity) ? (unitPrice * mieQuantity * newRow.vatCode.rate) : vatCode ? vatCode.rate : newRow.vatCode.rate;
        // console.log("Temp Amount: ",temp_vatamount,"Manual Amount: ",manaul_vatamount);
        const vatAmmount = (temp_vatamount === manaul_vatamount) ? temp_vatamount : (vat_amount.vat_amount_manual === false) ? temp_vatamount : manaul_vatamount;
        // const vatAmmount = (unitPrice && newRow.vatCode && mieQuantity) ? (unitPrice * mieQuantity * newRow.vatCode.rate) : vatCode ? vatCode.rate : newRow.vatCode.rate;
        // const vatAmmount = (vat_amount.vat_amount_manual === true) ? manaul_vatamount : (unitPrice && newRow.vatCode && mieQuantity) ? (unitPrice * mieQuantity * newRow.vatCode.rate) : vatCode ? vatCode.rate : newRow.vatCode.rate;      
        setVat_amount({ ...vat_amount, vat_amount_manual: true });  
        //const vatAmmount =  unitPrice * mieQuantity * newRow.vatCode.rate;
        //const lineValue = (unitPrice && mieQuantity) ? unitPrice * mieQuantity : ""
        const lineValue = unitPrice * mieQuantity;
        // eslint-disable-next-line max-len
        const updatedRow = { ...newRow, product: productName, mieQuantity, unitPrice, vatCode, vatAmmount: isNaN(vatAmmount) ? 0 : Number((Math.round(vatAmmount * 100) / 100).toFixed(2)), lineValue: Number((Math.round(lineValue * 100) / 100).toFixed(2)) };
        const ids = newRow.id;
        const newBx = rows;
        newBx.forEach(x => {
            if (x.id === ids) {
                x.lineValue = updatedRow.lineValue
                x.mieQuantity = updatedRow.mieQuantity
                x.product = updatedRow.product
                x.spc = updatedRow.spc
                x.unitPrice = updatedRow.unitPrice
                x.vatAmmount = updatedRow.vatAmmount
                x.vatCode = updatedRow.vatCode
            }
        });
        //handle send data to api
        doCalculation();
        return updatedRow;
    };

    const formatText = (text) => {
        // First convert to uppercase
        let result = text.toUpperCase();
    
        // Handle the case where both CR and CBS appear together at the end
        result = result.replace(/CRCBS$/, 'CR CBS');
    
        // Handle individual CR or CBS at the end, but only if they're not already spaced
        result = result.replace(/([^\s])CR$/, '$1 CR');
        result = result.replace(/([^\s])CBS$/, '$1 CBS');
        
        // Handle the case where CBS CR appears at the end
        result = result.replace(/([^\s])CBS CR$/, '$1 CBS CR');
        result = result.replace(/([^\s])CR CBS$/, '$1 CR CBS');
    
        return result;
      };
    

    const handleInputChange = (event) => {
        const { value, name } = event.target;         
        if(name === "supplierInvoiceCode") {
            let newV = formatText(value);
            setState({ ...state, [name]: newV })
        } else {
            setState({ ...state, [name]: value })
        }
        
    }

    const convertNegativeValue = () => {
        let newBx = rows;
        newBx.forEach(x => {
            if (x.lineValue.toString().includes('-')) {
                x.lineValue = Number(x.lineValue)
            } if (!x.lineValue.toString().includes('-')) {
                x.lineValue = negativeNumber(x.lineValue)
            }
            if (x.mieQuantity.toString().includes('-')) {
                x.mieQuantity = Number(x.mieQuantity)
            } if (!x.mieQuantity.toString().includes('-')) {
                x.mieQuantity = negativeNumber(x.mieQuantity)
            }
            if (x.vatAmmount.toString().includes('-')) {
                x.vatAmmount = Number(x.vatAmmount)
            } if (!x.vatAmmount.toString().includes('-')) {
                x.vatAmmount = negativeNumber(x.vatAmmount)
            }
        });
        setTimeout(() => {
            setRows(newBx)
        }, 50);
        setConvertMinus(!convertMinus)
    }

    const doCalculation = async () => {
        let newBx = rows;
        let netTotal = 0;
        let netVatTotal = 0;
        newBx.forEach(x => {
            if (x.lineValue) {
                netTotal = netTotal + x.lineValue
            }
            if (x.vatAmmount) {
                netVatTotal = netVatTotal + x.vatAmmount
            }
        });
        setState({ ...state, netTotal: (Math.round(netTotal * 100) / 100).toFixed(2), vatTotal: (Math.round(netVatTotal * 100) / 100).toFixed(2), grossTotal: (Math.round((netTotal + netVatTotal) * 100) / 100).toFixed(2) })
        if (state.submitDisable === false) {
            setState({ ...state, submitDisable: true })
        }
    }

    useEffect(() => {
        let newBx = rows;
        if (newBx.length > 0) {
            if (!convertMinus) {
                newBx.forEach(x => {
                    x.lineValue = negativeremove(x.lineValue)
                    x.mieQuantity = negativeremove(x.mieQuantity)
                    x.unitPrice = negativeremove(x.unitPrice)
                    x.vatAmmount = negativeremove(x.vatAmmount)
                });
            }

            setTimeout(() => {
                setRows([]);
            }, 100);
            setTimeout(() => {
                setRows(newBx);
            }, 200);
        }
    }, [convertMinus])

    const onDeleteRow = (e, params) => {
        e.preventDefault();
        const filteredRows = rows.filter(row => {
            if (row.id !== params.id) {
                return row;
            }
        })
        setRows(filteredRows)

        if (filteredRows.length === 0) {
            setState({ ...state, netTotal: '', vatTotal: '', grossTotal: '' })
        }

    }
    const resetAll = (event) => {

        setTimeout(() => {

            dispatch(mieSearch({
                parent: 'mie_supplier',
                key: 'supplier_code_selected',
                value: null
            }))
            setMie_supplier(null)
            dispatch(mieSearch({
                parent: 'mie_distribution_channel',
                key: 'distribution_channel_selected',
                value: null
            }))
            setMie_distributionChannel(null)
            dispatch(setSupplierCode({
                key: 'mieSupplierCodeOnDashboard',
                value: ""
            }))
            dispatch(setDateValue({
                key: 'invoiceDate',
                value: null
            }))
            dispatch(setDateValue({
                key: 'deliveryDate',
                value: null
            }))
            setState({ ...state, supplierInvoiceCode: '', netTotal: '', vatTotal: '', grossTotal: '', supplierdashboardname: '', supplierdashboardcode: '', pageError: false, finalSubmitError: '' })

        }, 10);
        setTimeout(() => {
            dispatch(setExistingInvoice({
                key: 'existingData',
                value: null
            }))
        }, 175);
        setTimeout(() => {
            setRows([])
        }, 225);
    }

    const [mie_supplier, setMie_supplier] = useState("")
    const [mie_supplierValue, setMie_supplierValue]= useState("")

    const [mie_distributionChannel, setMie_distributionChannel] = useState("")
    const [distributionChannelValue, setDistributionChannelValue] = useState("")

    const validateInvoice = async () => {

        const supplierMemberAccountCode = manualInvoiceEntry.mieSupplierCodeOnDashboard ? manualInvoiceEntry.mieSupplierCodeOnDashboard.smaCode : "";
        const supplierInvoiceCode = state.supplierInvoiceCode ? state.supplierInvoiceCode : ""
        const deliveryDate = manualInvoiceEntry.deliveryDate ? manualInvoiceEntry.deliveryDate : ""
        const invoiceDate = manualInvoiceEntry.invoiceDate ? manualInvoiceEntry.invoiceDate : ""

        if (mie_supplier && mie_distributionChannel && supplierMemberAccountCode &&
            supplierInvoiceCode && deliveryDate && invoiceDate && rows.length > 0 && ((state.netTotal && state.vatTotal && state.grossTotal) !== "")) {
            if (moment(manualInvoiceEntry.deliveryDate).format('DD/MM/YYYY') === 'Invalid date' || moment(manualInvoiceEntry.invoiceDate).format('DD/MM/YYYY') === 'Invalid date') {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else {
                doCalculation().then(async () => {

                    let linesValues = [];
                    rows.forEach(x => {
                        linesValues.push({
                            supplierProductCode: x.spc.supplierProductCode,
                            product: { id: x.spc.productId, name: x.spc.productName },
                            supplierQuantity: x.mieQuantity,
                            supplierUnitPrice: Number(x.unitPrice).toFixed(2),
                            vatCode: x.vatCode,
                            vatAmount: x.vatAmmount,
                            supplierLineValue: x.lineValue,
                            systemDispute: 0,
                            userDispute: 0,
                            description: (x.description !== undefined && x.spc.productName === "") ? x.description : x.spc.productName
                        })
                    });
                    const validatePayload = {
                        "supplier": mie_supplier,
                        "distributionChannel": mie_distributionChannel,
                        "memberOutlet": {
                            "id": manualInvoiceEntry.mieSupplierCodeOnDashboard ? manualInvoiceEntry.mieSupplierCodeOnDashboard.memberOutLetId : "",
                            "onTrade": manualInvoiceEntry.mieSupplierCodeOnDashboard ? manualInvoiceEntry.mieSupplierCodeOnDashboard.onTrade : ""
                        },
                        "grossTotal": state.grossTotal,
                        "netTotal": state.netTotal,
                        "vatTotal": state.vatTotal,
                        "deliveryDate": manualInvoiceEntry.deliveryDate ? moment(manualInvoiceEntry.deliveryDate).format('DD/MM/YYYY') : null,
                        "invoiceDate": manualInvoiceEntry.invoiceDate ? moment(manualInvoiceEntry.invoiceDate).format('DD/MM/YYYY') : null,
                        "supplierMemberAccountCode": manualInvoiceEntry.mieSupplierCodeOnDashboard ? manualInvoiceEntry.mieSupplierCodeOnDashboard.smaCode : "",
                        "supplierInvoiceCode": state.supplierInvoiceCode ? state.supplierInvoiceCode : "",
                        "onTrade": manualInvoiceEntry.mieSupplierCodeOnDashboard ? manualInvoiceEntry.mieSupplierCodeOnDashboard.onTrade : "",
                        "sourceUri": "ManualEntry",
                        "lines": linesValues
                    }
                    try {
                        setState({ ...state, pageLoader: true })
                        const { data } = await axios_invoice_summaries.post(`/invoice/validate`, validatePayload);
                        if (data.success) {
                            setState({ ...state, pageError: false, validationError: [], pageLoader: false, submitDisable: false, validateInvoiceData: validatePayload })
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                        } else if (data.success === false) {
                            setState({ ...state, pageLoader: false, pageError: true, validationError: data.resultMap.result, submitDisable: true, validateInvoiceData: null })
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                        }

                    } catch (error) {
                        const { response: { data, status } } = error;
                        if (!data) {
                            setSnackBar({ ...snackBar, show: true, text: error.message ? error.message : "System took long to respond, please retry!", type: "error" })
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" })
                        }
                        setState({ ...state, pageLoader: false, pageError: false, validationError: [], submitDisable: true })
                    }
                })
            }

        } else if (!mie_supplier || !mie_distributionChannel || !supplierMemberAccountCode ||
            !supplierInvoiceCode || !deliveryDate || !invoiceDate) {
            setSnackBar({ ...snackBar, show: true, text: 'Please select mandatory fields', type: "error" })
        } else {
            setSnackBar({ ...snackBar, show: true, text: 'Please add at least 1 Line Entry', type: "error" })
        }


    }
    const finalInvoiceSubmit = async () => {
        if (state.submitDisable === false && state.validateInvoiceData !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_invoice_summaries.post(`/invoice/process`, state.validateInvoiceData);
                if (data) {
                    setState({ ...state, pageLoader: false, finalSubmitError: data.overallType, submitError: data.entries, submitDisable: true, validateInvoiceData: null })
                    setSnackBar({ ...snackBar, show: true, text: 'Manual Invoice Submitted', type: "success" })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message ? error.message : "System took long to respond, please retry!", type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" })
                }
                setState({ ...state, pageLoader: false, finalSubmitError: '', submitError: [], submitDisable: true })
            }
        }
    }
    const addManualEntry = (param) => {
        if (param === false) {
            setState({ ...state, handleManualEntryModal: param })
        } else {
            setState({ ...state, handleManualEntryModal: true })
        }
    }
    const onAddManualEntry = (params) => {
        setState({ ...state, handleManualEntryModal: false, netTotal: Number(params.netTotal), vatTotal: Number(params.vatTotal), grossTotal: params.grossTotal })
        let newRows = [...rows];
        let lines = [];
        params.lines.forEach(x => {
            lines.push({
                description: "MAN INV",
                id: 'manualEntryId',
                spc: { supplierProductCode: x.supplierProductCode, productName: "", productId: "" },
                product: "",
                mieQuantity: x.supplierQuantity ? x.supplierQuantity : 0,
                unitPrice: x.supplierUnitPrice,
                vatCode: x.vatCode,
                vatAmmount: x.vatAmount,
                lineValue: x.supplierLineValue,
            })
        });
        let obj = newRows.findIndex(o => o.id === 'manualEntryId');
        setTimeout(() => {
            if (obj !== -1) {
                // eslint-disable-next-line prefer-destructuring
                newRows[obj] = lines[0]
                setRows([...newRows])

            } else {
                setRows([...lines, ...rows])
            }
        }, 150);
        setTimeout(() => {
            setRowModesModel((oldModel) => ({
                ...oldModel,
                manualEntryId: { mode: GridRowModes.Edit, fieldToFocus: 'spc' },
            }));
        }, 160);

    }

    useEffect(() => {
        if (state.submitDisable === false) {
            setState({ ...state, submitDisable: true })
        }
    }, [state.supplierInvoiceCode, manualInvoiceEntry.mie_supplier, manualInvoiceEntry.mie_distribution_channel,
    manualInvoiceEntry.invoiceDate, manualInvoiceEntry.deliveryDate, manualInvoiceEntry.mieSupplierCodeOnDashboard, manualInvoiceEntry.setSpcValue])

    const editRow = (e, props) => {
        e.preventDefault();
        const { id, value, field } = props;
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'spc' },
        }));
    }
    const removeEditRow = (e, props) => {
        e.preventDefault();
        const { id, value, field } = props;
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.View },
        }));
    }

    return <>
        {state.pageLoader && <PageLoader />}
        <Container disableGutters={true} maxWidth={false}>
            <BreadcrumbNavs pageTitle="Manual Invoice Entry" />
            <Box sx={{ marginBottom: 2.5 }}>
                <Box component="div" className="filter-panel-box">
                    <Box component="div" className='filter-fields' sx={{ columnGap: 3, display: 'grid', gridTemplateColumns: { sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' } }}>
                        <Box component="div">
                            <Autocomplete
                                value={mie_supplier || null}
                                getOptionLabel={(option) => option.name || ""}
                                onChange={(_, newValue) => {
                                    setMie_supplier(newValue)
                                    dispatch(mieSearch({
                                        parent: 'mie_supplier',
                                        key: 'supplier_code_selected',
                                        value: newValue
                                    }))
                                }}
                                inputValue={mie_supplierValue}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            fontSize: '0.85rem'
                                        }
                                    }
                                }}
                                onInputChange={(_, newInputValue) => {
                                    setMie_supplierValue(newInputValue)
                                }}
                                id="mie_supplier_search"
                                options={manualInvoiceEntry.mie_supplier.supplier_code_options}
                                renderInput={(params) =>
                                    <FormControl size={'small'} fullWidth>
                                        <FormLabel htmlFor="mie_supplier" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Supplier</FormLabel>
                                        <Box component="div" >
                                            <TextField {...params}
                                                size="small"
                                                id="mie_supplier"
                                                aria-describedby="mie_supplier-error"
                                            />
                                        </Box>
                                    </FormControl>
                                }
                            />
                        </Box>
                        {/* <Box component="div">
                            <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Supplier</FormLabel>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={11}>
                                    <Item>
                                        <Box component="div" sx={{ paddingTop: '6px' }}>
                                            <FormControl size={'small'} fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="mie_supplier"
                                                    aria-describedby="mie_supplier_error"
                                                    value={(manualInvoiceEntry.mie_supplier.supplier_code_selected ? manualInvoiceEntry.mie_supplier.supplier_code_selected.name : '')}
                                                    name="mie_supplier"
                                                    sx={{ pointerEvents: 'none' }}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Item>
                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => clearSMC(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box> */}
                        <Box component="div">
                            <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Supplier Member Account Code</FormLabel>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={11}>
                                    <Item>
                                        <Box component="div" sx={{ paddingTop: '6px' }}>
                                            <FormControl size={'small'} fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="pdc_gen_product"
                                                    aria-describedby="pdc_gen_product_error"
                                                    value={(state.supplierdashboardcode ? state.supplierdashboardcode.smaCode : '')}
                                                    name="pdc_gen_product"
                                                    sx={{ pointerEvents: 'none' }}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Item>
                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => clearSMC(e, 'smacode')} startIcon={<ClearIcon />}></Button>
                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'smacode')} startIcon={<EditIcon />}></Button>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box component="div">
                            <Autocomplete
                                value={mie_distributionChannel || null}
                                getOptionLabel={(option) => option.name || ""}
                                onChange={(_, newValue) => {
                                    setMie_distributionChannel(newValue)
                                    dispatch(mieSearch({
                                        parent: 'mie_distribution_channel',
                                        key: 'distribution_channel_selected',
                                        value: newValue
                                    }))
                                }}
                                inputValue={distributionChannelValue}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            fontSize: '0.85rem'
                                        }
                                    }
                                }}
                                onInputChange={(_, newInputValue) => {
                                    setDistributionChannelValue(newInputValue)
                                    dispatch(mieSearch({
                                        parent: 'mie_distribution_channel',
                                        key: 'distribution_channel_selected',
                                        value: newInputValue
                                    }))
                                }}
                                id="mie_dc_search"
                                options={manualInvoiceEntry.mie_distribution_channel.distribution_channel_options}
                                renderInput={(params) =>
                                    <FormControl size={'small'} fullWidth>
                                        <FormLabel htmlFor="mie_distribution_channel" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Distribution Channel</FormLabel>
                                        <Box component="div" >
                                            <TextField {...params}
                                                size="small"
                                                id="mie_distribution_channel"
                                                aria-describedby="mie_distribution_channel-error"

                                            />
                                        </Box>
                                    </FormControl>
                                }
                            />
                        </Box>

                        <Box component="div">
                            <FormControl size={'small'} fullWidth>
                                <FormLabel htmlFor="supplierInvoiceCode" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Supplier Invoice Code</FormLabel>
                                <TextField
                                    inputProps={{ maxLength: 100 }}
                                    size="small"
                                    id="supplierInvoiceCode"
                                    aria-describedby="supplierInvoiceCode_error"
                                    value={state.supplierInvoiceCode}
                                    name="supplierInvoiceCode"
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <FormLabel htmlFor="supplierInvoiceCode" sx={{ paddingTop: '5px', paddingBottom: '0 !important', paddingLeft: '5px', fontWeight: 'normal', fontSize: '12px' }}>Please ensure there is a space between SIC, CR, and CBS (e.g., 123123 CR CBS).</FormLabel>
                            </FormControl>
                        </Box>
                        <Box component="div">
                            <FormControl size={'small'} fullWidth>
                                <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Invoice Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={manualInvoiceEntry.invoiceDate}
                                        onChange={(e) => { handleInvoiceDate(e) }}
                                        name="invoiceDate"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" className='date-picker-input'>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField size="small" id="invoiceDate"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="invoiceDate_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        <Box component="div">
                            <FormControl size={'small'} fullWidth>
                                <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Delivery Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={manualInvoiceEntry.deliveryDate}
                                        onChange={(e) => { handleDeliveryDate(e) }}
                                        name="deliveryDate"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" className='date-picker-input'>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField size="small" id="deliveryDate"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="deliveryDate_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                    </Box>
                    <Divider sx={{ marginY: { xs: 1.5, sm: 0, md: 0 }, borderStyle: 'dashed', borderColor: "#919396", borderWidth: '1px' }} />
                    <Box component="div" className='filter-checkboxes' sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box component="div" className='filterChkBx' sx={{ columnGap: 3, display: 'flex', alignItems: 'center' }}>

                        </Box>
                        <Box component="div" sx={{ columnGap: 2.5, display: 'flex', justifyContent: { xs: 'center', sm: 'end' }, alignItems: 'center' }}>
                            <Button variant="outlined" onClick={(e) => { resetAll(e) }}>Reset All</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                <div className='table-actionable'>
                    <Button variant="contained" color="tertiary" onClick={addManualEntry} size='small' startIcon={<AddIcon />}>
                        Add Manual Entry
                    </Button>
                </div>
                <div className='table-actionable'>
                    <Button variant="contained" onClick={addNewEntry} size='small' startIcon={<AddIcon />}>
                        Add Entry
                    </Button>
                </div>
                <div className='table-actionable'>
                    <Button variant="contained" color="secondary" size='small' onClick={handleExistingInvoiceModal} startIcon={<SendIcon />}>
                        Existing Invoices
                    </Button>
                </div>
            </Box>
            <div style={{ height: '468px', width: '100%', marginTop: '45px' }}>
                <DataGridPro
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns ? columns : []}
                    //autoHeight={true}
                    disableColumnMenu={true}

                    rowCount={state.total}
                    loading={state.isLoading}

                    pagination={true}
                    page={state.page - 1}
                    pageSize={state.pageSize}
                    paginationMode={'client'}
                    onPageChange={(newPage) => onPageChange(newPage)}
                    onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                    components={{
                        Pagination: CustomPagination,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Result
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Result
                            </Stack>
                        )
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100, 200, 300]}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                    }
                    initialState={{ pinnedColumns: { right: ['action'] } }}
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowHeight={() => { return 'auto' }}
                    getRowId={(row) => row.supplierProductCode ? row.supplierProductCode : row.id}
                    processRowUpdate={processRowUpdate}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                    sx={{
                        "& .MuiDataGrid-columnHeader": {
                            backgroundColor: "#F0F0F0"
                            // color: "red"
                        },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "600",
                            fontSize: '14px',
                            color: "#15191E"
                        },
                        "& .MuiDataGrid-row.oddRow": {
                            backgroundColor: "#FBFBFB"
                            // color: "red"
                        },
                        "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                            backgroundColor: "#FFF5E1"
                            // color: "red"
                        },
                        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                            borderRight: `1px solid #E6E6E6`,
                            borderBottom: `1px solid #E6E6E6`,
                        },
                        '& .MuiDataGrid-cell': {
                            fontWeight: '500',
                        },
                    }}
                />
            </div>

            <Box component="div" className='filter-checkboxes' sx={{ paddingTop: 3, display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, justifyContent: 'end', alignItems: 'center', flexWrap: "wrap" }}>
                <Box component="div" sx={{ columnGap: 3, display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, alignItems: 'center' }}>
                    <Box sx={{ columnGap: 1.5, display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, textAlign: { xs: 'center' }, paddingBottom: { xs: 2, sm: 0, md: 0, lg: 0 }, alignItems: 'center' }}>
                        <Box component="div">
                            <Button variant="contained" color="secondary" onClick={doCalculation} sx={{ color: '#fff' }}>
                                Calculate
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ columnGap: 1.5, display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, textAlign: { xs: 'center' }, paddingBottom: { xs: 2, sm: 0, md: 0, lg: 0 }, alignItems: 'center' }}>
                        <Box component="div">
                            <Button variant="outlined" onClick={convertNegativeValue}>
                                Convert Sign
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ columnGap: 1.5, display: 'flex', paddingBottom: { xs: 2, sm: 0, md: 0, lg: 0 }, alignItems: 'center' }}>
                        <FormLabel htmlFor="rate" className="fontSemiBold">Net Total</FormLabel>
                        <Box component="div" sx={{ maxWidth: 125 }}>
                            <FormControl size={'small'} fullWidth>
                                <TextField
                                    inputProps={{ maxLength: 100 }}
                                    size="small"
                                    id="netTotal"
                                    aria-describedby="netTotal_error"
                                    name="netTotal"
                                    onChange={(e) => handleInputChange(e)}
                                    value={state.netTotal}
                                    disabled={true}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ columnGap: 1.5, display: 'flex', paddingBottom: { xs: 2, sm: 0, md: 0, lg: 0 }, alignItems: 'center' }}>
                        <FormLabel htmlFor="rate" className="fontSemiBold">Vat Total</FormLabel>
                        <Box component="div" sx={{ maxWidth: 125 }}>
                            <FormControl size={'small'} fullWidth>
                                <TextField
                                    inputProps={{ maxLength: 100 }}
                                    size="small"
                                    id="vatTotal"
                                    aria-describedby="vatTotal_error"
                                    name="vatTotal"
                                    onChange={(e) => handleInputChange(e)}
                                    value={state.vatTotal}
                                    disabled={true}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ columnGap: 1.5, display: 'flex', alignItems: 'center' }}>
                        <FormLabel htmlFor="rate" className="fontSemiBold">Gross Total</FormLabel>
                        <Box component="div" sx={{ maxWidth: 125 }}>
                            <FormControl size={'small'} fullWidth>
                                <TextField
                                    inputProps={{ maxLength: 100 }}
                                    size="small"
                                    id="grossTotal"
                                    aria-describedby="grossTotal_error"
                                    name="grossTotal"
                                    onChange={(e) => handleInputChange(e)}
                                    value={state.grossTotal}
                                    disabled={true}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box component="div" sx={{ width: '100%', paddingTop: 3, columnGap: 3, display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, alignItems: 'center', justifyContent: 'end' }}>
                    <Box sx={{ columnGap: 1.5, display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, textAlign: { xs: 'center' }, paddingBottom: { xs: 2, sm: 0, md: 0, lg: 0 }, alignItems: 'center' }}>
                        <Box component="div">
                            <LoadingButton
                                loading={state.loader}
                                loadingPosition="center"
                                endIcon=""
                                variant="contained"
                                type="submit"
                                disabled={vat_code.validateDisable}
                                sx={{ marginRight: '15px' }}
                                onClick={validateInvoice}
                            >Validate</LoadingButton>
                            <LoadingButton
                                loading={state.loader}
                                loadingPosition="center"
                                endIcon=""
                                variant="contained"
                                type="submit"
                                disabled={state.submitDisable}
                                onClick={finalInvoiceSubmit}
                            >Submit </LoadingButton>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{alignSelf: 'flex-start', width: '100%', display: 'flex'}}>
                    {state.pageError && <Box sx={{ marginY: 2.5, marginLeft: '10px' }}>
                        {
                            state.validationError.map((el) => <Alert severity="error" key={el.error}>{el.error}</Alert>)
                        }
                    </Box>}
                    {state.finalSubmitError === ('Fatal' || 'Error') && <Box sx={{ marginY: 2.5, marginLeft: '10px' }}>
                        {
                            state.submitError.map((el, index) => {
                                if (el.type === ("Fatal" || "Error")) {
                                    return <Alert severity="error" key={el.message + index}>{el.message}</Alert>
                                }
                            })
                        }
                    </Box>}
                </Box>
            </Box>
            {
                manualInvoiceEntry.existingInvoiceModal && (
                    <ExistingInvoiceModal
                        onShow={manualInvoiceEntry.existingInvoiceModal}
                        onHide={handleExistingInvoiceModal}
                        onExitingSelection={onExitingSelection}
                    />
                )
            }
            {
                state.supplierMemberDasboadModal && <CommonPopUp
                    onShow={state.supplierMemberDasboadModal}
                    size="large_overlay"
                    supplierMemberCode={{
                        title: 'Supplier Details',
                        type: 'supplier'
                    }}
                    memberaccount={(e) => getSupplierDetail(e, state.handleSupplierMemberModal)}
                    onHide={(e, reason) => hideSupplierMemberModal(e, reason, state.handleSupplierMemberModal)}
                />
            }
            {
                state.supplierCodeDasboadModal && <CommonPopUp
                    onShow={state.supplierCodeDasboadModal}
                    size="large_overlay"
                    supplierMemberCode={{
                        title: 'Supplier Member Account',
                        fromDashboard: true,
                        supplier: manualInvoiceEntry.mie_supplier.supplier_code_selected || null
                    }}
                    memberaccount={(e) => getSupplierDetail(e, state.handleSupplierMemberModal)}
                    onHide={(e, reason) => hideSupplierMemberModal(e, reason, state.handleSupplierMemberModal)}
                />
            }
            {
                state.handleManualEntryModal &&
                <AddManualEntryModal onShow={state.handleManualEntryModal}
                    onHide={(e) => { addManualEntry(e) }}
                    onAddManualEntry={onAddManualEntry}
                />
            }

            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </Container>
    </>
}

export default ManualEntryInvoice;