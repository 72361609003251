import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, TextField, FormControl } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import PageLoader from "../../components/shared/PageLoader";
import { styled } from '@mui/material/styles';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import axiosRetroDiscountSchemes from '../../services/utility/axios-retro-discount-schemes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { DataTableRetroDiscountSchemes } from "../../components/shared/data-table/DataTableRetroDiscountSchemes";
import { setHasEditData, setrdsoeEffectiveDate, brandSearch, setrdsoeDateList, setrdsoeDeleteData, setDeleteList, rdsoeInput, resetrdsoe } from "../../store/slices/rdsoe";
import { setMemberCode } from '../../store/slices/supplierMemberAccounts';
import AddEditRetroDiscountSchemesOutletExclusionsDetail from "./AddEditRetroDiscountSchemesOutletExclusions/AddEditRetroDiscountSchemesOutletExclusionsDetail";
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";
import { AutoCompleteStatic } from '../../components/shared/form/AutoCompleteStatic';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, rdsoe_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.rdsoes.editTabData,
            rdsoe_fields: state.rdsoes
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_aggregation_group') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            //dispatch(hasTabEdit({ editTab: false }))
            setEditPDClData(row);

        } else if (action === 'delete') {
            onDelete(row)
        }
        setAnchorEl(null);
    };
    const setEditPDClData = async (row) => { 
        dispatch(setHasEditData(row))
        dispatch(setrdsoeEffectiveDate({
            key: 'rdsoe_start_date',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setrdsoeEffectiveDate({
            key: 'rdsoe_end_date',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY') : ""
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_brand_owner',
            key: 'rdsoe_brand_owner_selected',
            value: row.brandOwner
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_retro_discount',
            key: 'rdsoe_retro_discount_selected',
            value: row.retroDiscountScheme
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_member',
            key: 'rdsoe_member_name',
            value: row.memberOutlet.memberAccount.member ? row.memberOutlet.memberAccount.member : null
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_member_account',
            key: 'rdsoe_member_account_selected',
            value: row.memberOutlet.memberAccount
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_member_outlet',
            key: 'rdsoe_member_outlet_selected',
            value: row.memberOutlet
        }))
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_aggregation_group')}>Edit</MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem> */}
        </Menu>
    </>)
}
function RetrospectiveDiscountSchemesOutletExclusions() {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "Scheme ID",
            field: "outletId",
            flex: 1,
            minWidth: 90
        },
        {
            headerName: "Scheme Name",
            field: "schemeName",
            width: 150
        },
        {
            headerName: "Brand Owner ID",
            field: "brandOwnerId",
            width: 180
        },
        {
            headerName: "Brand Owner Name",
            field: "brandOwnerName",
            width: 170
        },
        {
            headerName: "Member Name",
            field: "memberName",
            width: 180
        },
        {
            headerName: "Member Account Name",
            field: "memberAccountName",
            width: 180
        },
        {
            headerName: "Member Outlet Name",
            field: "memberOutletName",
            width: 170
        },
        {
            headerName: "Outlet Orwell ID",
            field: "outletOrwellId",
            width: 180,
            valueGetter: (params) => { return (params.row.memberOutlet.orwellOutletid)},
        },
        {
            headerName: "Start Date",
            field: "startDate",
            width: 170
        },
        {
            headerName: "End Date",
            field: "endDate",
            width: 180
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteRdsoeData} />;
            }
        }
    ];
   
    const [state, setState] = useState({
        openrdsoesModal: false,
        memberListModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Retro Discount Schemes Outlet Exclusions",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'retrodiscount/outlet-exclusion/filter',
            searchUrl: 'retrodiscount/outlet-exclusion/filter'
        }
    });
    const [pageLoader, setPageLoader] = useState(false);

    const { rdsoe_retro_discount_scheme, rdsoe_brand_owner, rdsoe_member, addProductwarning, showAddEditModal, sma_container, rdsoe_fields } = useSelector((state) => {
        return {
            rdsoe_retro_discount_scheme: state.externalFilter.rdsoe_retro_discount_scheme,
            rdsoe_brand_owner: state.externalFilter.rdsoe_brand_owner,
            rdsoe_member: state.externalFilter.rdsoe_member,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            sma_container: state.smas,
            rdsoe_fields: state.rdsoes
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "schemeName": rdsoe_retro_discount_scheme,
                "brandOwnerName": rdsoe_brand_owner,
                "memberName": rdsoe_member.name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((rdsoe_retro_discount_scheme || rdsoe_brand_owner || rdsoe_member) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setFilter({
                key: 'rdsoe_member',
                value: ''
            }))
        }
        dispatch(filterResetFlagChange(true));
    }
    const addRdsoeModal = () => {
        dispatch(resetrdsoe())
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewRdsoeModal = (event) => {
        setState({ ...state, openrdsoesModal: false })
    }

    const hideSupplierMemberModal = (params, reason) => {
        if (reason) {
            dispatch(setFilter({
                key: 'rdsoe_member',
                value: reason ? reason.name : null
            }))
        }
        setState({ ...state, memberListModal: params === false ? params : true })
    }
    const resetLookups = () => {
        dispatch(setFilter({
            key: 'rdsoe_member',
            value: ''
        }))
    }
    const CommonPopUpModal = () => {
        setState({...state, memberListModal: true})
    } 

    const deleteRdsoeData = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setrdsoeDeleteData({
            key: 'rdsoeokDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (rdsoe_fields.rdsoedeleteProcess) {
            deleteSingleRow()
        }
    }, [rdsoe_fields.rdsoedeleteProcess])

    const deleteSingleRow = async () => {
        if (rdsoe_fields.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true });
                let deleterdsoeData = null;
                deleterdsoeData = {
                    "id": rdsoe_fields.deleteList.outletId
                }
                const { data } = await axiosRetroDiscountSchemes.delete(`retrodiscount/outlet-exclusion/${rdsoe_fields.deleteList.id}/deleteRetroDiscountOutlet`, { data: deleterdsoeData });
                if (data.success) {
                    setState({ ...state, pageLoader: false });
                    dispatch(setDeleteList(null));
                    resetSingleDeleteRowDispatch();
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Retro Discount Schemes Outlet Exclusions Delete Successfully", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    // try {
                    //     const { data } = await axiosRetroDiscountSchemes.get(`retrodiscount/outlet-exclusion`);
                    //     if (data !== null || data !== undefined) {
                    //         dispatch(setrdsoeDateList(data.items))
                    //         setSnackBar({ ...snackBar, show: true, text: "Retro Discount Schemes Outlet Exclusions Delete Successfully", type: "success" })
                    //         setPageLoader(false)
                    //         dispatch(setDeleteList(null))
                    //         resetSingleDeleteRowDispatch()
                    //         setTimeout(() => {
                    //             window.location.reload();
                    //         }, 2000);
                    //     }
                    // }
                    // catch (error) {
                    //     const { response: { data, status } } = error;
                    //     if (status === 500 && data.errorType === "Business") {
                    //         setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    //     } else {
                    //         setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     }
                    //     setState({ ...state, pageLoader: false })
                    //     resetSingleDeleteRowDispatch()
                    // }
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setrdsoeDeleteData({
            key: 'rdsoeokDelete',
            value: false
        }))
        dispatch(setrdsoeDeleteData({
            key: 'rdsoedeleteProcess',
            value: false
        }))
    }

    // useEffect(() => {
    //     /* load first first time */
    //     filterBtn();
    // }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Retro Discount Schemes Outlet Exclusions" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Retro Discount Scheme" id="rdsoe_retro_discount_scheme" size="small" /></Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Brand Owner" id="rdsoe_brand_owner" size="small" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="members/look-up?pageSize=500" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="rdsoe_member" label="Member" name="accounts_member" /></Box>
                            {/* <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Member" id="rdsoe_member" size="small" /></Box> */}
                            {/* <Box component="div">
                                    <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Member</FormLabel>
                                    <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>
                                        <Grid item xs={12} md={11}>
                                            <Item>
                                                <Box component="div">
                                                    <FormControl size={'small'} fullWidth>
                                                        <TextField
                                                            inputProps={{ maxLength: 100 }}
                                                            size="small"
                                                            id="rdsoe_member"
                                                            value={(rdsoe_member ? rdsoe_member : '')}
                                                            aria-describedby="rdsoe_member_error"
                                                            name="rdsoe_member"
                                                            disabled={true}
                                                        />

                                                    </FormControl>
                                                </Box>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Item>
                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetLookups} startIcon={<ClearIcon />}></Button>
                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                            </Item>
                                        </Grid>

                                    </Grid>
                                </Box> */}
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addRdsoeModal(e)} startIcon={<AddIcon />}>New Retro Discount Schemes Outlet Exclusions</Button>
                    </div>
                </Box>
                <DataTableRetroDiscountSchemes loc="retrodsoe" defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableRetroDiscountSchemes> 
                {
                    showAddEditModal && (
                        <AddEditRetroDiscountSchemesOutletExclusionsDetail size="medium_overlay"
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideNewRdsoeModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
            </Container>
            {snackBar.show && <SnackBarDialog
            onShow={snackBar.show}
            text={snackBar.text}
            type={snackBar.type}
            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
        />}

        {
            state.memberListModal && <CommonPopUp
                onShow={state.memberListModal}
                size="large_overlay"
                supplierMemberCode={{
                    title: 'Member Details',
                    type: 'members'
                }}
                onHide={(e, reason) => hideSupplierMemberModal(e, reason)}
            />
        }
        </>
    );
}

export default RetrospectiveDiscountSchemesOutletExclusions;