import { DataTableNusslReports } from "../../components/shared/data-table/DataTableNusslReports";
import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SelectDropDown } from "../../components/shared/form/Select";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setNusslReportFromDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { saveSomeData } from "../../services/utility/common-utils";
import axiosNusslReports from "../../services/utility/axios-nussl-reports";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";


const IconMenuLink = ({ params, onDownload }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'download') {
            if (onDownload) {
                onDownload(row)
            }
        }
        setAnchorEl(null);
    };
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >

            <MenuItem onClick={(e) => handleClose(e, 'download')}>Download</MenuItem>
        </Menu>
    </>)
}
function NusslReport() {
    const columns = [
        {
            headerName: "Id",
            field: "id",
            minWidth: 50,
        },
        {
            headerName: "PID",
            field: "processId",
            minWidth: 100,
        },
        {
            headerName: "Created on",
            field: "creationDate",
            minWidth: 120,

        },
        {
            headerName: "File Name",
            field: "fileName",
            minWidth: 90,
            flex: 1.5,
        },
        {
            headerName: "Server Process",
            field: "type",
            minWidth: 90,
            flex: 1.5,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDownload={onDownload} />;
            },
        }
    ];
    const outputTypes = [{ text: 'Accounting Entry', value: 'AccountingEntry' }, { text: 'Disputed Invoice', value: 'DisputedInvoice' }, { text: 'Pay Member Retro', value: 'PayMemberRetro' }, { text: 'Statement Cycle Reconciliation', value: 'StatementCycleReconciliation' }];

    const [state, setState] = useState({
        openNewProductModal: false,
        defaultGrid: {
            pagetitle: "NUSSL Group Reports",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'nusslReports',
            searchUrl: 'nusslReports/filter'
        },
        pageLoader: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const { fileNames, fromDate, outputType, addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            fileNames: state.externalFilter.file_name,
            fromDate: state.externalFilter.from_date,
            outputType: state.externalFilter.output_type,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
        }
    });

    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY');
        dispatch(setNusslReportFromDate(sDate))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (fileNames !== '' && outputType !== '' && fromDate !== null && fromDate !== '') {
            dynamicFilterObjects = {
                "fileName": fileNames,
                "creationDate": moment(fromDate).format('DD/MM/YYYY'),
                "type": outputType
            }

        }
        if (fileNames !== '' && outputType === '' && (fromDate === null || !fromDate)) {
            dynamicFilterObjects = {
                "fileName": fileNames
            }

        }
        if (fileNames !== '' && outputType !== '' && (fromDate === null || !fromDate)) {
            dynamicFilterObjects = {
                "fileName": fileNames,
                "type": outputType
            }

        }
        if (fileNames !== '' && outputType === '' && (fromDate !== null || fromDate)) {
            dynamicFilterObjects = {
                "fileName": fileNames,
                "creationDate": moment(fromDate).format('DD/MM/YYYY')
            }
                ;
        }
        if (fileNames === '' && outputType !== '' && (fromDate === null || !fromDate)) {
            dynamicFilterObjects = {
                "type": outputType,
            }

        }
        if (fileNames === '' && outputType !== '' && (fromDate !== null || fromDate)) {
            dynamicFilterObjects = {
                "creationDate": moment(fromDate).format('DD/MM/YYYY'),
                "type": outputType,
            }

        }
        if (fileNames === '' && outputType === '' && (fromDate !== null || fromDate)) {
            dynamicFilterObjects = {
                "creationDate": moment(fromDate).format('DD/MM/YYYY')
            }

        }

        if (fromDate !== "" && fromDate !== null && moment(fromDate).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }))
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        if ((fileNames || outputType || fromDate) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));

    }
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }

    const onDownload = async (params) => {

        try {
            setState({ ...state, pageLoader: true })
            const fileDetails = {
                id: params.id,
                fileName: params.fileName
            }
            const { data } = await axiosNusslReports.post('/download/report', fileDetails);
            if (data) {

                setState({
                    ...state,
                    pageLoader: false,
                })

                saveSomeData(data.content, params.mimeType, data.fileName)
                setSnackBar({ ...snackBar, show: true, text: "File downloaded!", type: "success" })
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
        }
    }

    // useEffect(() => {
    //     /* load first first time */
    //     filterBtn();
    // }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="NUSSL Group Reports" />
                <Box sx={{ marginBottom: 5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={outputTypes} size="small" id="output_type" label="Output Type" name="output_type" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="price_start_date" className="fontBold">From Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={fromDate}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="from_date" label="From Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>

                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="from_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="from_date_error"
                                                        autoComplete="off"

                                                    />

                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="File Name" id="file_name" size="small" /></Box>

                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        {/* <Button variant="contained" size='small' onClick={(e) => addProductModal(e)} startIcon={<AddIcon />}>
                            Add New Levy Schemes
                        </Button> */}
                    </div>
                </Box>
                <DataTableNusslReports defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableNusslReports>
                {
                    // showAddEditModal && (
                    //     <AddEditProduct tabs={tabs}
                    //         onShow={showAddEditModal}
                    //         onHide={(e, reason) => hideNewProductModal(e, reason)}

                    //     />
                    // )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default NusslReport;
