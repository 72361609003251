import { DataTableNusslReports } from "../../components/shared/data-table/DataTableNusslReports";
import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, Autocomplete } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SelectDropDown } from "../../components/shared/form/Select";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setReportsFromDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import PageLoader from "../../components/shared/PageLoader";
import axiosNusslReports from '../../services/utility/axios-nussl-reports';
import { saveSomeData } from "../../services/utility/common-utils";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import axiosStatementCycle from "../../services/utility/axios-statement-cycle"
import { useRef } from "react";
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params, onDownload }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'download') {
            if (onDownload) {
                onDownload(row)
            }
        }
        setAnchorEl(null);
    };
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >

            <MenuItem onClick={(e) => handleClose(e, 'download')}>Download</MenuItem>
        </Menu>
    </>)
}
function Reports() {
    const columns = [
        {
            headerName: "Pid",
            field: "processId",
            minWidth: 50,
        },
        {
            headerName: "File Name",
            field: "fileName",
            minWidth: 200,
            flex: 1.5,
        },
        {
            headerName: "Creation Date",
            field: "creationDate",
            minWidth: 120,

        },
        {
            headerName: "Mime Type",
            field: "mimeType",
            minWidth: 100,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDownload={onDownload} />;
            },
        }
    ];

    const StatementCycleStages = [{ text: 'Discounts Billed', value: '2' }, { text: 'Member Statement', value: '3' }, { text: 'Member Collection', value: '4' }, { text: 'Supplier Paid', value: '5' }];

    const [state, setState] = useState({
        openNewProductModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Reports",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'reports',
            searchUrl: 'reports/filter'
        },
        serverProcessModal: false
    });

    const [statementCycleListId, setStatementCycleListId] = useState({
        selected_id: null,
        options: []
    })

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const { serverProcessID, fileNamev, statementCycleID, statementCycleStagev, creationDatev, addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            serverProcessID: state.externalFilter.r_sp_id,
            fileNamev: state.externalFilter.r_file_name,
            statementCycleID: state.externalFilter.r_sc_id,
            statementCycleStagev: state.externalFilter.r_sc_stage,
            creationDatev: state.externalFilter.r_creation_date,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
        }
    });

    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        console.log(id, value);
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY');
        dispatch(setReportsFromDate(sDate))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (serverProcessID !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "serverProcessId": serverProcessID
            }

        }
        if (fileNamev !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "fileName": fileNamev
            }
        }
        if (statementCycleID !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "statementCycleId": statementCycleID.id
            }
        }
        if (statementCycleStagev !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "statementCycleStage": statementCycleStagev
            }
        }
        if (creationDatev !== null && creationDatev !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "creationDate": moment(creationDatev).format('DD/MM/YYYY')
            }
        }

        if (creationDatev !== "" && creationDatev !== null && moment(creationDatev).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }))
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        if ((serverProcessID || fileNamev || statementCycleID || statementCycleStagev || creationDatev) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            if (autoC.current) {
                // eslint-disable-next-line prefer-destructuring
                const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                // eslint-disable-next-line prefer-destructuring
                const ele2 = autoC.current.getElementsByClassName('MuiOutlinedInput-input')[0];
                if (ele) {
                    //console.log('els ', ele);
                    ele.click();
                    ele2.blur()

                }
            }
            dispatch(setFilter({
                key: 'r_sp_id',
                value: ""
            }))
        }
        dispatch(filterResetFlagChange(true));
    }
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }

    const onDownload = async (params) => {

        try {
            setState({ ...state, pageLoader: true })
            const fileDetails = {
                id: params.id,
                fileName: params.fileName
            }
            const { data } = await axiosNusslReports.post('/download/report', fileDetails);
            if (data) {

                setState({
                    ...state,
                    pageLoader: false,
                })

                saveSomeData(data.content, params.mimeType, data.fileName)
                setSnackBar({ ...snackBar, show: true, text: "File Downloaded", type: "success" })
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
        }
    }

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (statementCycleListId.options.length === 0) {
                axiosStatementCycle.get('/statementcycle/statementcycleid/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setStatementCycleListId({ ...statementCycleListId, options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const autoC = useRef(null)
    const clearSMC = () => {
        dispatch(setFilter({
            key: 'r_sp_id',
            value: ""
        }))
    }
    const hideSupplierMemberModal = (params) => {

        setState({ ...state, serverProcessModal: params === false ? params : true })
    }
    const getProductDetail = (processId) => {
        if (processId !== '') {
            dispatch(setFilter({
                key: 'r_sp_id',
                value: processId.id
            }))
            setState({ ...state, serverProcessModal: false })
        }
    }

    // useEffect(() => {
    //     /* load first first time */
    //     filterBtn();
    // }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Reports" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div">
                                <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }} className="mandatory mandatory2">Server Process</FormLabel>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }}>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="pdc_gen_product"
                                                        aria-describedby="pdc_gen_product_error"
                                                        value={serverProcessID}
                                                        name="pdc_gen_product"
                                                        sx={{ pointerEvents: 'none' }}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={clearSMC} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={hideSupplierMemberModal} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid>
                            </Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="File Name" id="r_file_name" size="small" /></Box>
                            <Box component="div">
                                <FormControl size={'small'} fullWidth>
                                    <FormLabel htmlFor="config_name">Statement Cycle Id</FormLabel>
                                    <Autocomplete
                                        ref={autoC}
                                        id="searchStatementId"
                                        options={statementCycleListId.options}
                                        getOptionLabel={(option) => {
                                            return option.id.toString()
                                        }}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    fontSize: '0.85rem'
                                                }
                                            }
                                        }}
                                        onChange={(event, newValue) => {
                                            if (event.target.value !== "" || event.target.value !== null) {
                                                setStatementCycleListId({ ...statementCycleListId, selected_id: newValue })
                                                dispatch(setFilter({
                                                    key: 'r_sc_id',
                                                    value: newValue
                                                }))
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <FormControl size={'small'} fullWidth>
                                                <div className="input-group">
                                                    <TextField {...params}
                                                        size="small"
                                                        id="searchStatementCycleId"
                                                        aria-describedby="searchStatementCycleId-error"
                                                    />
                                                </div>
                                            </FormControl>
                                        }
                                    />
                                </FormControl>
                            </Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={StatementCycleStages} size="small" id="r_sc_stage" label="Statement Cycle Stage" name="r_sc_stage" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="price_start_date" className="fontBold">Creation Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={creationDatev}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="r_creation_date" label="From Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>

                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="r_creation_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="r_creation_date"
                                                        autoComplete="off"

                                                    />

                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>



                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                {/* <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                     <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> 
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' startIcon={<SendIcon />}>
                            Email Invoices
                        </Button>
                    </div>
                </Box> */}
                <DataTableNusslReports defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableNusslReports>
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
                {
                    state.serverProcessModal && <CommonPopUp
                        onShow={state.serverProcessModal}
                        size="large_overlay"
                        serverProcessFilter={true}
                        onHide={hideSupplierMemberModal}
                        memberaccount={getProductDetail}
                    />
                }
            </Container>

        </>
    );
}

export default Reports;
